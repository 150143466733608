<template>
  <div v-if="option" class="product-variant product-variant--color">
    <div class="product-variant__heading">
      <SfProperty :name="$t('Color')" :value="selectedVariant.label" />
    </div>
    <div class="product-variant__colors">
      <SfColor
        v-for="color in option.values"
        :key="color.uid"
        class="product-variant__color"
        :class="{
          'product-variant__color--out-of-stock': getDisabledForOption(color.out_of_stock) || disabled,
        }"
        :color="
          color?.swatch_data?.value.indexOf('#') === 0
            ? color?.swatch_data?.value
            : `url(https://www.my-jewellery.com/media/attribute/swatch/swatch_image/30x20${productGetters.getSwatchData(
                color.swatch_data,
              )})`
        "
        :selected="selectedVariant.uid === color.uid"
        :disabled="disabled ? true : null"
        :data-sku="selectedProductSku(color.uid)"
        :data-id="selectedProductId(color.uid)"
        :data-label="color.label"
        :data-attribute_code="option.attribute_code"
        @click="() => selectVariant(color)"
      >
        <template #badge>
          <SfBadge class="sf-color__badge" :class="{ 'sf-color__badge--selected': selectedVariant.uid === color.uid }">
            <CheckmarkIcon
              :style="{
                color: getContrast(color?.swatch_data?.value.indexOf('#') === 0 ? color?.swatch_data?.value : null),
              }"
            />
            <BellIcon v-if="getDisabledForOption(color.out_of_stock)" id="bell-icon" />
          </SfBadge>
        </template>
      </SfColor>
    </div>
  </div>
</template>

<script setup lang="ts">
import productGetters from '~/utils/getters/magentoProductGetters'
import CheckmarkIcon from '~/assets/icons/checkmark-alt.svg'
import BellIcon from '~/assets/icons/bell.svg'
import { getContrast } from '~/utils/colorHelpers'
import getIsDisabled from '~/utils/getIsDisabledProductVariantOption'
import { getSelectedProductVariantByUid } from '~/utils/productGetters'

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  productConfiguration: {
    type: Object,
    required: true,
  },
  type: {
    type: String as PropType<'wishlist' | 'cart'>,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['updateProductConfiguration'])

const uiState = useUiState()
const { setSoldOutFormData } = uiState
const { currentSlideIndex } = storeToRefs(uiState)
const { products } = storeToRefs(useProductStore())

const selectedVariant = computed(
  () => props.option.values.find((value) => value.uid === props.productConfiguration[props.option.attribute_uid]) || {},
)

const selectVariant = (value) => {
  const product = products.value[props.sku]
  const configuration = { ...props.productConfiguration, [props.option.attribute_uid]: value.uid }
  const variantId = product.variants?.find((variant) =>
    variant?.attributes?.every((attr) => Object.values(configuration).includes(attr?.uid)),
  )?.product?.id

  if (value?.out_of_stock === 'OUT_OF_STOCK' && variantId && props.type === 'cart') {
    return setSoldOutFormData({
      isModalVisible: true,
      optionLabel: value.label,
      productId: product.pid,
      variantId,
      type: 'soldOut',
    })
  }
  emit('updateProductConfiguration', { key: props.option.attribute_uid, value: value.uid })
  currentSlideIndex.value = 0
}

const getDisabledForOption = (out_of_stock_status) =>
  out_of_stock_status === 'OUT_OF_STOCK' && props.type !== 'wishlist'

const disabled = computed(() => (getIsDisabled(props) ? true : null))

const selectedProductSku = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku
const selectedProductId = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id
</script>

<style lang="scss" scoped>
.product-variant__colors {
  display: flex;
  gap: var(--spacer-xs);
}
.product-variant__color {
  --color-size: var(--variant-button-height);
  --color-border-radius: var(--spacer-2xs);
  background-size: cover;
  transition: all 0.12s ease;
  border: 1px solid var(--black-color);

  .sf-color__badge {
    --badge-padding: 0;
    --badge-height: 14px;
    --badge-background: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.12s ease;

    &:hover,
    &--selected {
      visibility: visible;
      opacity: 1;
    }
  }

  &--white {
    border: 1px solid var(--gray-dark-accent-color);
    --badge-color: var(--black-color);
  }

  &--out-of-stock {
    .sf-badge {
      visibility: visible;
      opacity: 1;
      height: 100%;
      width: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.75);
      opacity: 1;
      backdrop-filter: blur(15px);
      box-shadow: inset 0 0 0px 1px var(--gray-dark-accent-color);
      border-radius: 0.25rem;
    }

    svg#bell-icon {
      position: absolute;
      z-index: 1;
      opacity: 1;
      top: -3px;
      right: -4px;
      color: var(--black-color);
    }
  }

  &.is-active,
  &:hover {
    transform: none;
  }

  &.is-active {
    border: 1px solid var(--black-secondary-color);

    &:not(:hover):not(:focus) {
      --color-box-shadow-opacity: 0;
    }
  }
}
</style>
