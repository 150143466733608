<template>
  <div v-if="product" class="product__actions">
    <slot>
      <SfNotification
        :visible="errorNoVariantSelected"
        :message="
          $t('Please select {names}', {
            names: productNames,
          })
        "
        :persistent="false"
      />
      <template v-if="product.stock_status === 'OUT_OF_STOCK'">
        <ProductSoldOutForm>
          <ProductAddToWishList
            class="form-button product__add-to-wishlist sf-button--outline product__add-to-wishlist-button"
            type="button"
            :product="product"
          />
        </ProductSoldOutForm>
      </template>

      <div v-else class="product__add-to-cart" :class="{ 'product__add-to-cart--modal': isAtcModal }">
        <ProductAddToCartButton :product :product-configuration />
        <ProductBrands v-if="!isAtcModal" />
      </div>
    </slot>

    <slot name="additional-actions">
      <div v-if="product.stock_status !== 'OUT_OF_STOCK'" class="product__additional-actions">
        <ProductAddToWishList
          v-if="!isAtcModal"
          class="form-button product__add-to-wishlist sf-button--outline product__add-to-wishlist-button"
          :product="product"
          :selectedVariant="selectedProduct"
        />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { getProductOptionsNames, getSelectedProductVariant } from '~/utils/productGetters'
import type { Product } from '~/types/product'

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    default: () => ({}),
  },
  productConfiguration: {
    type: Object,
    default: () => ({}),
  },
  isAtcModal: {
    type: Boolean,
    default: false,
  },
})

const { setSoldOutFormData } = useUiState()
const errorNoVariantSelected = useState('errorNoVariantSelected', () => false)
const productNames = getProductOptionsNames(props.product)
const selectedProduct = computed(() => getSelectedProductVariant(props.product, props.productConfiguration))

onMounted(() => {
  if (props.product?.stock_status === 'OUT_OF_STOCK') {
    setSoldOutFormData({
      productId: props.product?.pid,
      variantId: selectedProduct.value?.id,
      type: 'soldOut',
    })
  }
})

onUnmounted(() => {
  if (props.product?.stock_status === 'OUT_OF_STOCK')
    setSoldOutFormData({ productId: null, variantId: null, optionLabel: null, type: null, isModalVisible: false })
})
</script>

<style lang="scss">
.product {
  &__actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacer-sm);

    @include for-mobile {
      padding: 0 var(--spacer-15);
    }
  }

  &__add-to-cart {
    flex-flow: column;
    margin: 0;
    flex-grow: 1;

    .sf-add-to-cart__button {
      width: 100%;
    }
  }

  &__add-to-wishlist {
    &.form-button {
      min-width: 46px;
      margin-left: var(--spacer-xs);

      @include for-desktop {
        min-width: 54px;
      }
    }
  }

  &__additional-actions {
    align-self: self-start;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0 var(--spacer-xs);
  }

  .sf-notification {
    width: 100%;
    padding: 8px 8px 8px 16px;
    margin: 0 0 var(--spacer-sm);
    background-color: var(--red-background-color);
    color: var(--red-background-color) !important;

    .sf-icon,
    .sf-button {
      display: none;
    }

    &__message {
      color: black;
    }
  }
}

.product__add-to-cart--modal {
  button {
    --button-background: var(--cta-pink-color);
    --button-color: var(--white-color);

    &:hover{
      --button-background: var(--cta-pink-color);
    }

    &.is-disabled--button {
      --button-color: var(--white-color);
      --button-background: var(--primary-color);
    }
  }
}
</style>
