<template>
  <SfButton
    class="sf-add-to-cart__button sf-button"
    :class="{
      'is-disabled--button': disabled,
      'ab24025-is-added-to-cart': ab24025productIsInCart && !disabled,
    }"
    :data-sku="selectedProduct.sku"
    :data-id="selectedProduct.id"
    data-testid="add-to-cart"
    :data-a-or-b="ab24025"
    @click="addItemToCart()"
  >
    <Checkmark v-if="ab24025productIsInCart && !disabled" />
    {{ addToCartButtonText }}
    <div v-if="isLoadingAddToCart" class="adding-to-cart">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </SfButton>
</template>

<script setup lang="ts">
import { getSelectedProductVariant } from '~/utils/productGetters'
import stockStatusEnum from '~/enums/stockStatusEnum'
import type { Product } from '~/types/product'
import Checkmark from '../assets/icons/checkmark.svg'

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    default: () => ({}),
  },
  productConfiguration: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['noVariantSelected'])

const { t } = useI18n()
const { tests } = storeToRefs(useAbTestStore())
const { sendAbTestEvent } = useAbTestStore()
const { cart } = storeToRefs(useCartStore())
const { addItem } = useCart()
const productStore = useProductStore()
const { productCustomOptionsConfiguration, giftcardHolder, loading } = storeToRefs(productStore)
const { productCustomOptionsCanAddToCartHandler } = productStore
const errorNoVariantSelected = useState('errorNoVariantSelected', () => false)
const { showAtcModal } = storeToRefs(useCartStore())

const productConfigurationCanAddToCart = computed(() => {
  const selectedOptions = Object.values(props.productConfiguration || {}).sort(sortStrings)
  if (!selectedOptions.length) return false
  return (
    props.product?.variants?.find((variant) =>
      variant.attributes.every((attribute) => selectedOptions.includes(attribute.uid)),
    )?.product.stock_status === 'IN_STOCK'
  )
})

const productCustomOptionsCanAddToCart = computed(() => productCustomOptionsCanAddToCartHandler(props.product.sku))

const canAddProductToCart = computed(() => {
  if (loading.value || !productCustomOptionsCanAddToCart.value) return false

  if (props.product?.__typename === 'ConfigurableProduct') {
    return productConfigurationCanAddToCart.value
  }

  return props.product?.stock_status === stockStatusEnum.inStock
})

const disabled = computed(() => !canAddProductToCart.value || isLoadingAddToCart.value || hasJustAddedToCart.value)

const ab24025productIsInCart = computed(() => {
  if (tests.value.get(24025) !== 'v1') return false
  const cartItem = cart.value?.items?.find((item) => {
    if (item.__typename === 'ConfigurableCartItem') {
      return item.configured_variant.sku === selectedProduct.value.sku
    }
    return item.product.sku === selectedProduct.value.sku
  })
  return !!cartItem
})

const addToCartButtonText = computed(() => {
  if (hasJustAddedToCart.value) {
    return t('Added!')
  }
  if (isLoadingAddToCart.value) {
    return t('Adding')
  }
  if (tests.value.get(24025) === 'v1') {
    return ab24025productIsInCart.value ? t('In cart') : t('Add to cart')
  }
  return t('Add to cart')
})

const ADDED_TO_CART_MESSAGE_TIMEOUT_MS = 400
const isLoadingAddToCart = ref(false)
const hasJustAddedToCart = ref(false)

const addItemToCart = async () => {
  if (isLoadingAddToCart.value || hasJustAddedToCart.value) {
    return
  }

  if (!canAddProductToCart.value) {
    errorNoVariantSelected.value = true
    return setTimeout(() => (errorNoVariantSelected.value = false), 5000)
  }

  isLoadingAddToCart.value = true
  try {
    if (giftcardHolder.value) {
      // Await or you'll have an out of sync cart
      await addItem({ product: giftcardHolder.value, quantity: 1 })
    }

    await addItem({
      product: props.product,
      quantity: 1,
      productConfiguration: props.productConfiguration,
      productCustomOptionsConfiguration: productCustomOptionsConfiguration.value,
    })

    hasJustAddedToCart.value = true
    setTimeout(() => {
      hasJustAddedToCart.value = false
      showAtcModal.value = false
    }, ADDED_TO_CART_MESSAGE_TIMEOUT_MS)
  } catch (err) {
    // if we don't catch the error, it bubbles up all the way to a 500 error
  } finally {
    isLoadingAddToCart.value = false
  }

  sendAbTestEvent(24025, 'show')
}

const selectedProduct = computed(() => getSelectedProductVariant(props.product, props.productConfiguration))

const ab24025 = ref()
onMounted(() => {
  ab24025.value = tests.value.get(24025)
})
</script>

<style lang="scss">
.product {
  .sf-add-to-cart__button {
    --button-background: var(--cta-pink-color);
    --button-width: 100%;

    &:hover,
    &:focus {
      --button-background: var(--cta-pink-color);
    }

    &.is-disabled--button {
      --button-background: var(--primary-color);
      --button-color: var(--white-color);
      pointer-events: all; // To allow for "no variant selected error"
    }

    &.ab24025-is-added-to-cart {
      --button-background: var(--white-color);
      --button-color: var(--cta-pink-color);
      --button-border: 1px solid var(--cta-pink-color);
      --button-border-width: 1px;
      gap: var(--spacer-xs);
    }

    .adding-to-cart {
      span {
        height: 3px;
        width: 3px;
        background-color: var(--white-color);
        border-radius: 100%;
        display: inline-block;
        opacity: 0;
        animation: m 1.2s infinite;

        &:nth-child(2) {
          animation-delay: 0.3s;
        }

        &:nth-child(3) {
          animation-delay: 0.6s;
        }
      }
    }

    @keyframes m {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
</style>
